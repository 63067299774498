import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const getHome = createAsyncThunk('sota/home', async () => {
    const response = await api.get(`/home`);
    return response.data;
});

export const home = createSlice({
    name: 'home',
    initialState: {
        data: {},
        loading: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getHome.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        });
        builder.addCase(getHome.fulfilled, (state, action) => {
            if (state.loading === 'pending') {
                state.data = action.payload;
                state.loading = 'idle';
            }
        });
        builder.addCase(getHome.rejected, (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                state.error = 'Error occured';
            }
        });
    }
});
export default home.reducer;
