import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHome } from '../../slices/homeSlice';

import AdvantagesSection from '../../components/AdvantagesSection';
import AdviceSection from '../../components/AdviceSection';
import BlockInfoSection from '../../components/BlockInfoSection';
import CitySection from '../../components/CitySection';
import ConnectionSection from '../../components/ConnectionSection';
import FooterSection from '../../components/FooterSection';
import IntControlSection from '../../components/IntControlSection';
import IntegratorSection from '../../components/IntegratorSection';
import LoraSection from '../../components/LorawanSection';
import NavSection from '../../components/Nav';
import RootImageSection from '../../components/RootImageSection';
import ServicesSection from '../../components/ServicesSection';
import SotaSection from '../../components/SotaSection';
import DevicesSection from '../../components/DevicesSection';

const HomePage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHome());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const data = useSelector((state) => state.knsRoot);

    return (
        <>
            {' '}
            <NavSection />
            <RootImageSection />
            <ConnectionSection />
            <SotaSection />
            <AdviceSection />
            <CitySection />
            <ServicesSection />
            <AdvantagesSection />
            <DevicesSection />
            <BlockInfoSection />
            <IntegratorSection />
            <LoraSection />
            <IntControlSection />
            <FooterSection />
        </>
    );
};

export default HomePage;
