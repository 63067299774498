import HomePage from './pages/home';
import React from 'react';
import theme from './theme';
import config from './config';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from './redux';
import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import './App.scss';

const App = () => {
    return (
        <HelmetProvider>
            <ReduxProvider store={store}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <Routes>
                            <Route exact path={config.defaultPath} element={<HomePage />} />
                            <Route path="*" exact={true} />
                        </Routes>
                    </ThemeProvider>
                </BrowserRouter>
            </ReduxProvider>
        </HelmetProvider>
    );
};

export default App;
