import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import menuIcon from '../../assets/icons/burger.svg';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Container, palette } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import logodark from '../../assets/logos/dark.svg';

const drawerWidth = 240;

const items = [
    { text: 'IoT Sota', link: '#' },
    { text: 'навіщо потрібен', link: '#' },
    { text: 'навіщо потрібен', link: '#' },
    { text: 'Розробники', link: '#' },
    { text: 'Сервіс', link: '#' },
    { text: 'Переваги', link: '#' }
];
const useStyles = makeStyles((theme) => ({
    nav: {
        backgroundColor: 'white!important',
        height: 94,
        alightItems: 'center',
        position: 'relative',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            backgroundColor: 'transparent!important',
            height: 64,
            boxShadow: 'none!important'
        },
        links: {
            textDecoration: 'none',
            justifyContent: 'space-between',
            marginLeft: 10,
            width: '100%'
        }
    }
}));

const NavSection = (props) => {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStyles();
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Container>
            <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ my: 2 }}>
                    <img src={logodark} alt="" />
                </Typography>
                <Divider />
                <List>
                    {items.map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton sx={{ textAlign: 'center' }}>
                                <ListItemText primary={item?.text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Container>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" className={classes.nav} position="absolute">
                <Container>
                    <Toolbar>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block', md: 'block', lg: 'block' } }}
                        >
                            <img src={logodark} alt="" />
                        </Typography>
                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }} className={classes.links}>
                            {items.map((item, index) => (
                                <Link key={index} className="navLink" to={item?.link}>
                                    {item?.text}
                                </Link>
                            ))}
                        </Box>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: 'block', lg: 'none', md: 'block' } }}
                        >
                            <img src={menuIcon} alt="" />
                        </IconButton>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    anchor="right"
                    ModalProps={{
                        keepMounted: true
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
};

export default NavSection;
