import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import BlockItem from './item';

const items = [
    {
        text: 'Збір даних з пристроїв обліку: світла, води, газу, тепла'
    },
    {
        text: 'Широкомасштабне покриття мережею в необхідних межах'
    },
    {
        text: 'Постійне оновлення та оптимізація системи без перебоїв зі зняттям показників'
    },
    {
        text: 'Управління потоком даних'
    },
    {
        text: 'Моніторинг стану пристроїв обліку'
    },
    {
        text: 'Дистанційна передача даних'
    },
    {
        text: 'Власний кабінет аналітики з широкими можливостями'
    },
    {
        text: 'Можливість оптимізувати службам процес надання послуг та сприяння розвитку'
    },
    {
        text: 'Створення своєї Екосистеми'
    },
    {
        text: 'Широка можливість розширення підключення пристроїв.'
    },
    {
        text: 'Додаткові рішення та масштабування'
    },
    {
        text: 'Свої IoT-сервер'
    }
];

const BlockInfoSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            backgroundColor: theme.palette.white,
            paddingBottom: 120,
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 20
            }
        },
        h1: {
            color: theme.palette.black,
            fontSize: 45,
            fontFamily: 'RF-Dewi-ex-Light',
            paddingTop: 70,
            paddingBottom: 10,
            maxWidth: 697,
            fontWeight: 300,
            [theme.breakpoints.down('sm')]: {
                fontSize: 32,
                paddingTop: 10
            }
        },
        p: {
            fontSize: 18,
            fontFamily: 'Montserrat-Regular',
            color: theme.palette.secondaryLightGray,
            lineHeight: '1.7',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        }
    }));
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Container>
                <h1 className={classes.h1}>IoTSOTA з`єднує фізичний і цифровий світ</h1>
                <p className={classes.p}>
                    Це технологічне рішення, що забезпечує ефективне та контрольоване надання комунальних послуг. IoTSOTA пропонує
                    комплексні рішення IoT, що спеціалізуються на вертикальних додатках та адаптують проекти IoT для підвищення
                    конкурентоспроможності промисловості.
                </p>
                <Grid container columnSpacing={4}>
                    {items.map((item, index) => (
                        <BlockItem props={item} index={index} key={index} />
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default BlockInfoSection;
