import React from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';

import backgroundImage from '../../assets/bg/city.jpg';

const CitySection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            width: '100%',
            height: '100%',
            background: `url(${backgroundImage}) no-repeat center center`,
            backgroundSize: 'cover',
            [theme.breakpoints.down('sm')]: {}
        },

        h1: {
            color: theme.palette.black,
            fontSize: 42,
            fontFamily: 'RF-Dewi-ex-Regular',
            paddingLeft: 88,
            paddingRight: 88,
            marginLeft: -88,
            paddingBottom: 38,
            fontWeight: 300,
            background: theme.palette.white,
            maxWidth: 688,
            zIndex: 10,
            [theme.breakpoints.down('sm')]: {
                fontSize: 32,
                marginLeft: 0,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 18,
                marginBottom: 60
            }
        },
        item: {
            borderRadius: 3,
            background: 'rgba(255, 255, 255, 0.30)',
            boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.15)',
            backdropFilter: 'blur(20px)',
            paddingTop: 43,
            paddingRight: 25,
            paddingLeft: 25,
            paddingBottom: 56
        },
        itemH: {
            height: 500,
            alignItems: 'end',
            position: 'relative',
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                paddingBottom: 40
            }
        },
        h2: {
            color: theme.palette.white,
            fontFamily: 'RF-Dewi-ex-Regular',
            fontSize: 20
        },
        psub: {
            color: theme.palette.white,
            fontFamily: 'Montserrat-Regular',
            fontSize: 17
        }
    }));
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Box className={classes.container}>
            <Container>
                <h1 className={classes.h1}>Ідеально підходить для міст</h1>
                <Grid container columnSpacing={6.75} sx={{ pb: { lg: 40, xs: 8 } }}>
                    <Grid item xs={12} sm={12} md={6} lg={4} className={classes.itemH}>
                        <Box className={classes.item}>
                            <h2 className={classes.h2}>Загальна вартість володіння мережею</h2>
                            <p className={classes.psub}>
                                Інтеграція приладів обліку не потребує великих витрат на розгортання та обслуговування інфраструктури.
                                Завдяки технологіям, які ми використовуємо, на виході отримаємо надійну передачу даних, велику дальність
                                роботи системи, простоту у масштабуванні та ущільненні.
                            </p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className={classes.itemH}>
                        <Box className={classes.item}>
                            <h2 className={classes.h2}>Гнучкість у розгортанні</h2>
                            <p className={classes.psub}>
                                Підприємства можуть створювати власні мережі, або кооперуватись з іншими підприємствами для більш ефективної
                                експлуатації системи.
                            </p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className={classes.itemH}>
                        <Box className={classes.item}>
                            <h2 className={classes.h2}>Розширені технічні можливості</h2>
                            <p className={classes.psub}>
                                Двонаправлена комунікація мережі LoRaWAN з малою потужністю, велике покриття і глибоке проникнення. Пристрої
                                можуть працювати у віддаленому, суворому середовищі. Висока надійність мережевої інфраструктури та датчиків.
                            </p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className={classes.itemH}>
                        <Box className={classes.item}>
                            <h2 className={classes.h2}>Безпека</h2>
                            <p className={classes.psub}>
                                Високий рівень безпеки мережі, завдяки використанню нових алгоритмів шифруванню та передачі даних, а ткож
                                збереження їх на захищених серверах.
                            </p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className={classes.itemH}>
                        <Box className={classes.item}>
                            <h2 className={classes.h2}>Постійна оптимізація</h2>
                            <p className={classes.psub}>
                                Термін служби акумулятора пристрою 10 років. Обладнання що встановлюэться на приладах обліку води,
                                електроенергії, газу тепла оптимізовані на автономну роботу до 10 років при постійному зв`язку з мережею.
                                Можливо керувати частотою передачі даних, що в свою чергу збільшує час експлуатації пристроїв.
                            </p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className={classes.itemH}>
                        <Box className={classes.item}>
                            <h2 className={classes.h2}>Екосистема</h2>
                            <p className={classes.psub}>
                                Швидкозростаюча відкрита екосистема завдяки гнучкості для інтеграції пристроїв від різних постачальників.
                            </p>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default CitySection;
