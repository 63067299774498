import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';

import figure_1 from '../../assets/icons/figure_1.svg';
import figure_2 from '../../assets/icons/figure_2.svg';
import figure_3 from '../../assets/icons/figure_3.svg';
import figure_4 from '../../assets/icons/figure_4.svg';
import figure_5 from '../../assets/icons/figure_5.svg';

const SotaSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            maxWidth: '100vw',
            position: 'relative',
            backgroundColor: 'rgba(196, 196, 196, 0.15)',
            paddingBottom: 100
        },
        h1: {
            color: 'black',
            fontSize: 45,
            fontFamily: 'RF-Dewi-ex-Light',
            paddingTop: 70,
            paddingBottom: 10,
            maxWidth: 468,
            fontWeight: 300,
            [theme.breakpoints.down('sm')]: {
                fontSize: 32
            }
        },
        sub: {
            fontSize: 16,
            fontFamily: 'Montserrat-Regular',
            maxWidth: 496,
            color: theme.palette.secondaryLightGray,
            lineHeight: '1.5',
            [theme.breakpoints.down('lg')]: {
                maxWidth: 396
            }
        },
        psub: {
            fontSize: 20,
            fontFamily: 'RF-Dewi-ex-Bold',
            marginLeft: 20
        },
        line: {
            display: 'flex'
        }
    }));
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Container>
                <h1 className={classes.h1}>Навіщо потрібен сервіс IoT SOTA?</h1>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.line}>
                            <img src={figure_1} alt="" />
                            <h1 className={classes.psub} style={{ width: 280 }}>
                                Використовуйте безперебійне постачання ресурсів
                            </h1>
                        </div>
                        <p className={classes.sub}>
                            Використовуйте технологію інтелектуальних мереж, щоб забезпечити безперебійне ефективне енерго-, водо-,
                            газо-постачання, максимізувати свою енергоефективність, монетизувати енергетичну гнучкість своїх промислових
                            активів та об`єктів.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.line}>
                            <img src={figure_2} alt="" />
                            <h1 className={classes.psub} style={{ width: 280 }}>
                                Дізнайтеся більше про силу IoT
                            </h1>
                        </div>
                        <p className={classes.sub}>
                            Відкрийте для себе всі наші пристрої на основі технології IoT та підключіть їх до хмари. Дізнайтеся більше про
                            силу Інтернету речей.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.line}>
                            <img src={figure_5} alt="" />
                            <h1 className={classes.psub} style={{ width: 170 }}>
                                Знаходьте нові рішення
                            </h1>
                        </div>
                        <p className={classes.sub}>
                            Постійна урбанізація, а також попит на збільшення ефективності. Компаніям потрібно збільшувати потужності,
                            розробляючи нові рішення для оптимізації управління наявними комунальними активами.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.line}>
                            <img src={figure_3} alt="" />
                            <h1 className={classes.psub} style={{ width: 180 }}>
                                Впроваджуйте інтелектуальні мережі
                            </h1>
                        </div>
                        <p className={classes.sub}>
                            Впровадження інтелектуальних мереж, розумних лічильників та нових способів генерування та транспортування
                            енергії, забезпечує модернізацію комунальних послуг, де споживання енергії, газу, води, світла чи тепла
                            контролюється та активно управляється з метою зменшення відходів, збереження та забезпечення сталого розвитку.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.line}>
                            <img src={figure_4} alt="" />
                            <h1 className={classes.psub} style={{ width: 330 }}>
                                Зосереджуйтесь на створенні нової цінності
                            </h1>
                        </div>
                        <p className={classes.sub}>
                            Розгортайте уніфіковану, масштабовану, багатофункціональну мережеву інфраструктуру пристроїв обліку для
                            комунальних підприємств та міст, забезпечуючи чітко визначені точки взаємодії між системами та споживачами.
                            Спрощуйте, та зменшуйте часозатрати на те, що можна зробити за лічені секунди завдяки нашому програмному
                            продукту. Зосереджуйтесь на створенні нової цінності для своїх споживачів, завдяки результатам, що покращують
                            ваші послуги та життя громадян.
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default SotaSection;
