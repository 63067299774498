import { createTheme } from '@mui/material';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },
    palette: {
        sky: '#16A0DB',
        black: 'black',
        white: 'white',
        gray: 'rgba(196, 196, 196, 0.15)',
        secondaryGray: 'rgba(152, 152, 152, 1)',
        secondaryLightGray: '#989898'
    },
    fonts: {},
    typography: {
        h1: {
            // Styles for h1 go here
        },
        h2: {
            // Styles for h2 go here
        },
        h3: {
            // Styles for h3 go here
        }
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    color: 'black',
                    backgroundColor: '#FFF',
                    border: '1px solid #000',
                    backdropFilter: 'blur(4px)',
                    fontSize: 18,
                    borderRadius: 0,
                    paddingLeft: 30,
                    textTransform: 'capitalize',
                    paddingRight: 30,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontFamily: 'RF-Dewi-ex-Regular'
                }
            }
        }
    }
});

export default theme;
