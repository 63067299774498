import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@mui/material';
import { Container } from '@mui/system';

import flame from '../../assets/icons/flame.svg';
import idea from '../../assets/icons/idea.svg';
import radiator from '../../assets/icons/radiator.svg';
import wave from '../../assets/icons/wave.svg';

const ConnectionSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            background: theme.palette.white,
            paddingBottom: 140
        },
        h1: {
            fontFamily: 'Montserrat-Bold',
            fontSize: 42,
            maxWidth: 700,
            [theme.breakpoints.down('lg')]: {
                fontSize: 32
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 32,
                paddingBottom: 10
            }
        },
        p: {
            fontSize: 18,
            fontFamily: 'Montserrat-Regular',
            maxWidth: 790,
            color: theme.palette.secondaryLightGray,
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                paddingBottom: 10
            }
        },
        sub: {
            fontSize: 18,
            fontFamily: 'Montserrat-Regular',
            maxWidth: 372,
            color: theme.palette.secondaryLightGray
        },
        psub: {
            fontSize: 18,
            fontFamily: 'RF-Dewi-ex-Bold',
            maxWidth: 372
        },
        dividerMain: {
            backgroundColor: theme.palette.sky,
            height: 3,
            width: 150
        },
        subItem: {
            fontSize: 18,
            fontFamily: 'RF-Dewi-ex-Light',
            fontWeight: 500
        },
        dividerItem: {
            backgroundColor: theme.palette.secondaryLightGray,
            [theme.breakpoints.down('lg')]: {
                display: 'none'
            }
        },
        itemContainer: {
            marginTop: 20,
            marginBottom: 20,
            [theme.breakpoints.down('lg')]: {
                width: '100%'
            }
        },
        subItemImage: {
            width: 50,
            height: 50
        }
    }));
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Container>
                <Grid container columnSpacing={2.75} sx={{ mt: 16 }}>
                    <Grid item xs={12} sm={12} md={12} lg={9}>
                        <div className={classes.h1}>Розширюйте свої можливості з підключенням до IoT</div>
                        <p className={classes.p}>
                            <b>IoT (інтернет речей) це</b> — мережа великої кількості електропристроїв, підключених до інтернету, що
                            використовують вбудовані датчики для одночасної роботи, збору, аналізу, та передачі даних за допомогою додатків.{' '}
                        </p>
                        <Divider className={classes.dividerMain} sx={{ mt: 4, mb: 3 }} />
                        <Grid container columnSpacing={2.75}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <h1 className={classes.psub}>Нові можливості</h1>
                                <p className={classes.sub}>
                                    З нами можна трансформувати свою бізнес-модель, розширити свої можливості та максимізувати вигоди від
                                    розгортання мережі.
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <h1 className={classes.psub}>Простота та швидкість</h1>
                                <p className={classes.sub}>
                                    Швидке, просте розгортання мережі обліку (моніторингу) та простий користувальницький інтерфейс для
                                    управління їхніми підключеннями.
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <h1 className={classes.psub}>Універсальність</h1>
                                <p className={classes.sub}>
                                    Підключення (зняття даних, та управління пристроями) пристроїв обліку до комунальних (не тільки) мереж.
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <h1 className={classes.psub}>Унікальність</h1>
                                <p className={classes.sub}>Власна розробка програмного забезпечення для IoT, що немає аналогів в Україні</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={3} sx={{ mt: { xs: 4 }, mb: { xs: -10 } }}>
                        <Grid container columnSpacing={2.75}>
                            <Grid item xs={3} sm={3} md={3} lg={12} textAlign={'center'}>
                                <div className={classes.itemContainer}>
                                    <img src={wave} className={classes.subItemImage} alt="" />
                                    <h3 className={classes.subItem}>Вода</h3>
                                </div>
                                <Divider className={classes.dividerItem} />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={12} textAlign={'center'}>
                                <div className={classes.itemContainer}>
                                    <img src={radiator} className={classes.subItemImage} alt="" />
                                    <h3 className={classes.subItem}>Тепло</h3>
                                </div>
                                <Divider className={classes.dividerItem} />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={12} textAlign={'center'}>
                                <div className={classes.itemContainer}>
                                    <img src={idea} className={classes.subItemImage} alt="" />
                                    <h3 className={classes.subItem}>Світло</h3>
                                </div>
                                <Divider className={classes.dividerItem} />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={12} textAlign={'center'}>
                                <div className={classes.itemContainer}>
                                    <img src={flame} className={classes.subItemImage} alt="" />
                                    <h3 className={classes.subItem}>Газ</h3>
                                </div>
                                <Divider className={classes.dividerItem} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ConnectionSection;
