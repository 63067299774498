import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

const BlockItem = ({ props, index }) => {
    const even = !(index % 2);
    const useStyles = makeStyles((theme) => ({
        p: {
            color: even ? theme.palette.black : theme.palette.sky,
            fontFamily: 'Montserrat-Regular',
            fontSize: 18,
            maxWidth: 260,
            fontWeight: 700,
            lineHeight: '1.7'
        },
        textContainer: {
            marginTop: 36,
            paddingLeft: 41,
            border: '1px solid black',
            minHeight: 188,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    }));

    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6} md={4} lg={4}>
            <div className={classes.textContainer}>
                <p className={classes.p}>{props.text}</p>
            </div>
        </Grid>
    );
};
export default BlockItem;
