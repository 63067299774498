import React from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';

import backgroundImage from '../../assets/bg/root.jpg';

const RootImageSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100vh',
            display: 'flex',
            alignItems: 'end',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                maxHeight: '100%',
                height: 800
            }
        },
        background: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: `url(${backgroundImage}) no-repeat center center fixed`,
            backgroundSize: 'cover',
            zIndex: -10
        },
        content: {
            maxWidth: 710,
            height: 650,
            textAlign: 'left',
            paddingLeft: 46,
            paddingTop: 46,
            zIndex: 1,
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(0 0 0 / 46%)',
            borderRadius: 0,
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                height: 580,
                marginBottom: 100,

                paddingLeft: 26,
                paddingRight: 26,
                paddingTop: 46
            }
        },
        h1: {
            color: theme.palette.white,
            fontSize: 45,
            fontFamily: 'RF-Dewi-ex-Regular',
            fontWeight: 300,
            [theme.breakpoints.down('sm')]: {
                fontSize: 26
            }
        },
        p: {
            color: theme.palette.white,
            fontSize: 22,
            maxWidth: 468,
            fontFamily: 'Montserrat-Regular',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                marginTop: 50,
                maxWidth: 268
            }
        },
        btn: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '70px!important'
            }
        }
    }));
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Box className={classes.container}>
            <div className={classes.background} />
            <Container>
                <div className={classes.content}>
                    {/* Your text and other content goes here */}
                    <h1 className={classes.h1}>Новітнє рішення в галузі управління мережами пристроїв зчитування даних</h1>
                    <p className={classes.p}>
                        Екосистема IoT - це безпечна передача даних незалежно від зовнішніх чинників, що стане на службу{' '}
                        <b>містам та громадам нашої країни</b>
                    </p>
                    <Button
                        className={classes.btn}
                        sx={{ background: theme.palette.sky, border: 0, backdropFilter: 'blur(10px)', color: theme.palette.white }}
                    >
                        Дізнатися більше
                    </Button>
                </div>
            </Container>
        </Box>
    );
};

export default RootImageSection;
