import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import ServiseItem from './item';

import section_1 from '../../assets/services/section1.jpg';

const items = [
    {
        title: 'Навчання',
        text: 'Курси та презентаційні семінари, щоб отримати всі знання для інтеграції нашого програмного забезпечення на вашому підприємстві, та розробки будь-якого рішення IoT для вас',
        img: section_1,
        btn: ''
    },
    {
        title: 'Консультація',
        text: 'Отримуйте поради щодо ПО та пристроїв обліку для підключення які найкраще відповідають цілям вашого проекту. Ми поділимося з вами своїми знаннями IoT',
        img: section_1,
        btn: ''
    },
    {
        title: 'Індивідуальні проєкти',
        text: 'Ми розробляємо та інтегруємо наш продукт для вас. Ми готові обговорити та втілити найсміливіші решення виходячи з ваших завдань, часових рамок та можливостей',
        img: section_1,
        btn: ''
    },
    {
        title: 'Технічна підтримка',
        text: 'Наші експерти допоможуть вирішити всі питання, пов’язане з нашим продуктом, або отримати технічну допомогу, яку ви потребуєте',
        img: section_1,
        btn: ''
    },
    {
        title: 'Хмарна зона',
        text: 'Керуйте данними які отримаєте  в режимі онлайн, з будь якого місця та в будь-який час',
        img: section_1,
        btn: ''
    }
];

const ServicesSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            maxWidth: '100vw',
            position: 'relative',
            backgroundColor: 'rgba(196, 196, 196, 0.15)',
            paddingBottom: 247,
            marginTop: -30,
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 10
            }
        },
        h1: {
            color: 'black',
            fontSize: 45,
            fontFamily: 'RF-Dewi-ex-Light',
            paddingTop: 170,
            paddingBottom: 15,
            fontWeight: 300,
            [theme.breakpoints.down('sm')]: {
                fontSize: 32,
                paddingTop: 80
            }
        }
    }));
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Container>
                <h1 className={classes.h1}>Послуги IoT SOTA</h1>
                {items.map((item, index) => (
                    <ServiseItem props={item} index={index} key={index} />
                ))}
            </Container>
        </Box>
    );
};

export default ServicesSection;
