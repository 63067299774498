import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import IntItem from './item';

import elect from '../../assets/items/el.jpg';
import gas from '../../assets/items/gas.jpg';
import heat from '../../assets/items/heat.jpg';
import water from '../../assets/items/water.jpg';

const items = [
    {
        title: 'Водопостачання',
        img: water
    },
    {
        title: 'Газопостачання',
        img: gas
    },
    {
        title: 'Електропостачання',
        img: elect
    },
    {
        title: 'Теплопостачання',
        img: heat
    }
];

const IntControlSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            position: 'relative',
            backgroundColor: theme.palette.white,
            paddingBottom: 160
        },
        h1: {
            color: theme.palette.black,
            fontSize: 50,
            fontFamily: 'RF-Dewi-ex-Regular',
            paddingTop: 70,
            paddingBottom: 40,
            maxWidth: 800,
            fontWeight: 400,
            [theme.breakpoints.down('sm')]: {
                fontSize: 32,
                paddingTop: 30
            }
        }
    }));
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Container>
                <h1 className={classes.h1}>Інтелектуальне управління ресурсами</h1>
                <Grid container columnSpacing={6}>
                    {items.map((item, index) => (
                        <IntItem props={item} key={index} />
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default IntControlSection;
