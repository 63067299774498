import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';

const IntItem = ({ props }) => {
    const useStyles = makeStyles((theme) => ({
        h3: {
            color: theme.palette.black,
            fontSize: 20,
            fontFamily: 'RF-Dewi-ex-Regular',
            paddingBottom: 10,
            paddingTop: 20,
            paddingLeft: 50,
            fontWeight: 500,
            textTransform: 'uppercase'
        },
        box: {
            marginBottom: 40
        },
        image: {
            width: '100%',
            height: 400,
            background: `url(${props.img}) no-repeat center center`,
            backgroundSize: 'cover',
            zIndex: 100
        }
    }));

    const classes = useStyles();
    return (
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box sx={{ border: 1 }} className={classes.box}>
                <div className={classes.image} />
                <h3 className={classes.h3}>{props.title}</h3>
            </Box>
        </Grid>
    );
};
export default IntItem;
