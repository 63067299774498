import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import backgroundImage from '../../assets/bg/lorawan.jpg';

const LoraSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            maxWidth: '100vw',
            position: 'relative',
            backgroundColor: theme.palette.white,
            marginBottom: 100,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 40
            }
        },
        h1: {
            color: theme.palette.black,
            fontSize: 50,
            fontFamily: 'RF-Dewi-ex-Regular',
            paddingTop: 70,
            paddingBottom: 10,
            maxWidth: 468,
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: 32
            }
        },
        background: {
            width: '100vw',
            height: 400,
            background: `url(${backgroundImage}) lightgray 50% / cover no-repeat`,

            [theme.breakpoints.down('sm')]: {
                height: 180,
                marginLeft: -16,
                marginRight: -16
            }
        },
        sub: {
            fontSize: 18,
            fontFamily: 'Montserrat-Regular',
            lineHeight: '1.7',
            maxWidth: 920,
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        },
        psub: {
            fontSize: 18,
            fontFamily: 'Montserrat-Regular',
            lineHeight: '1.7',
            fontWeight: 300,
            maxWidth: 520,
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        },
        line: {
            display: 'flex'
        }
    }));
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Container>
                <h1 className={classes.h1}>LoRaWAN®</h1>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className={classes.sub}>
                            LoRaWAN® - це протокол MAC (контроль доступу до медіа) та архітектура системи для мережі LoRa®. Він забезпечує
                            глобальний стандарт технології зв`язку LoRa® і забезпечує безперебійну сумісність пристроїв та мереж без
                            складних вимог щодо встановлення.
                        </p>
                    </Grid>
                    <div className={classes.background} />

                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 2, pr: 1 }}>
                        <p className={classes.psub}>
                            Розроблений для додатків Інтернету речей (IoT) LoRa® відповідає життєво важливим технічним вимогам, таким як
                            двонаправлений зв`язок, наскрізна безпека, послуги мобільності та локалізації. LoRa® зазвичай працює в межах
                            ліцензійних смуг радіодіапазонів під гігагерц, що дає кожному свободу в побудові мережі LoRaWAN®. Технологія
                            LoRa® забезпечує дуже тривалі передачі, використовуючи надзвичайно низьке енергоспоживання.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 2 }}>
                        <p className={classes.psub}>
                            LoRaWAN® - це мережевий протокол, на якому працює LoRa®. Специфікація LoRaWAN® безпосередньо впливає на час
                            автономної роботи пристрою, ємність мережі, якість обслуговування, безпеку та різноманітність програм, що
                            обслуговуються мережею. Протокол LoRaWAN® - це відкритий стандарт, розроблений та підтримуваний некомерційною
                            асоціацією, що називається LoRa Alliance®.
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default LoraSection;
