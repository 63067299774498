import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@mui/material';
import { Container } from '@mui/system';
import backgroundImage from '../../assets/bg/adv_bg.svg';

const AdvantagesSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            paddingTop: 120,
            background: theme.palette.white,
            marginBottom: 200,
            [theme.breakpoints.down('md')]: {
                paddingTop: 10,
                marginBottom: 100
            }
        },
        background: {
            position: 'absolute',
            backgroundSize: 'cover',
            marginTop: 50,
            marginLeft: -160,
            height: 960,
            zIndex: 0,
            [theme.breakpoints.down('md')]: {
                marginLeft: -280
            }
        },
        h1: {
            color: 'black',
            fontSize: 42,
            maxWidth: 620,
            fontWeight: 300,
            fontFamily: 'Montserrat-Regular'
        },
        advBlock: {
            paddingTop: 80,
            paddingLeft: 20,
            [theme.breakpoints.down('md')]: {
                paddingLeft: 90
            }
        },
        h2: {
            color: 'black',
            fontSize: 24,
            maxWidth: 280,
            fontWeight: 700,
            fontFamily: 'RF-Dewi-ex-Regular'
        },
        p: {
            color: theme.palette.secondaryLightGray,
            fontSize: 18,
            maxWidth: 346,
            fontWeight: 400,
            lineHeight: '1.7',
            fontFamily: 'Montserrat-Regular',
            [theme.breakpoints.down('md')]: {
                fontSize: 16,
                maxWidth: 506,
                paddingRight: 40
            }
        },
        divider: {
            backgroundColor: theme.palette.black,
            marginTop: '1em!important',
            marginBottom: '1em!important'
        }
    }));
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Container>
                <img className={classes.background} src={backgroundImage} alt="" />
                <Grid container>
                    <Grid item xs={12} sm={12} md={3} lg={4} />
                    <Grid item xs={12} sm={12} md={9} lg={8} className={classes.advBlock}>
                        <h1 className={classes.h1}>Переваги</h1>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <h2 className={classes.h2}>Комплексні рішення</h2>
                                <p className={classes.p}>
                                    Ми пропонуємо комплексні рішення, що адаптуються під ваші вимоги, та бізнес процеси, для підвищення
                                    ефективності
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <h2 className={classes.h2}>Максимальна ефективність</h2>
                                <p className={classes.p}>
                                    Екосистема IoTSOTA. Ми працюємо над об`єднанням всіх мереж партнерів, щоб забезпечити максимальну
                                    ефективність та моніторинг використання ресурсів.
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Divider className={classes.divider} sx={{ display: { xs: 'none' } }} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <h2 className={classes.h2}>Робота на великих відстанях</h2>
                                <p className={classes.p}>
                                    Можливість роботи на великих відстаннях. Базові станції можуть охоплювати цілі міста. Діапазон в значній
                                    мірі залежить від навколишнього середовища або перешкод у певному місці, але пристрої які ми
                                    використовуємо компенсують всі ці недоліки і маютьдіапазон зв`язку більший, ніж будь-яка інша
                                    стандартизована комунікаційна технологія.{' '}
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <h2 className={classes.h2}>Глобальний стандарт</h2>
                                <p className={classes.p}>
                                    Технологія передачі даних, яку ми інтегруємо — це глобальний стандарт, який використовується у всьому
                                    світі, що розпоряджається двостороннми комунікаціями з великим діапазоном (до 15 км) з дуже низьким
                                    енергоспоживанням, що дозволяє працювати на одній батареї до десяти років.{' '}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default AdvantagesSection;
