import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Divider, Grid } from '@mui/material';
import logoWhite from '../../assets/logos/white.svg';
import fb from '../../assets/icons/fb.svg';

const FooterSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            maxWidth: '100vw',
            position: 'relative',
            backgroundColor: theme.palette.black
        },
        h3: {
            color: theme.palette.white,
            fontFamily: 'RF-Dewi-ex-Regular',
            fontSize: 18
        },
        ul: {
            padding: 0,
            listStyle: 'none'
        },
        li: {
            color: theme.palette.secondaryLightGray,
            fontFamily: 'Montserrat-Regular',
            fontSize: 14,
            marginTop: 10
        },
        divider: {
            backgroundColor: theme.palette.white
        },
        navs: {
            paddingTop: 91,
            paddingBottom: 40
        },
        footer: {
            marginTop: 20,
            paddingBottom: 40,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            width: '100%',
            isplay: 'flex',
            flexDirection: 'column'
        },
        follow: {
            color: theme.palette.white,
            fontFamily: 'Montserrat-Regular',
            display: 'flex',
            marginTop: 10,
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                marginBottom: -12
            }
        },
        links: {
            color: theme.palette.secondaryLightGray,
            fontFamily: 'Montserrat-Regular',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        linksMobile: {
            color: theme.palette.secondaryLightGray,
            fontFamily: 'Montserrat-Regular',
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 15,
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        logo: {
            marginBottom: -10
        },
        netw: {
            marginRight: 20,
            marginTop: 5
        },
        followText: {
            marginRight: 20,
            marginTop: 8,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 5
            }
        }
    }));
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Container>
                <Grid container className={classes.navs}>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                        <h3 className={classes.h3}>IoT solutions</h3>
                        <ul className={classes.ul}>
                            <li className={classes.li}>Smart Agriculture</li>
                            <li className={classes.li}>Water Management</li>
                            <li className={classes.li}>Smart Cities</li>
                            <li className={classes.li}>Smart Parking</li>
                            <li className={classes.li}>Smart Retail</li>
                            <li className={classes.li}>Industry 4.0</li>
                            <li className={classes.li}>Smart Environment</li>
                            <li className={classes.li}>Smart Tourism</li>
                            <li className={classes.li}>Smart Tracking</li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                        <h3 className={classes.h3}>IoT solutions</h3>
                        <ul className={classes.ul}>
                            <li className={classes.li}>Smart Agriculture</li>
                            <li className={classes.li}>Water Management</li>
                            <li className={classes.li}>Smart Cities</li>
                            <li className={classes.li}>Smart Parking</li>
                            <li className={classes.li}>Smart Retail</li>
                            <li className={classes.li}>Industry 4.0</li>
                            <li className={classes.li}>Smart Environment</li>
                            <li className={classes.li}>Smart Tourism</li>
                            <li className={classes.li}>Smart Tracking</li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                        <h3 className={classes.h3}>IoT solutions</h3>
                        <ul className={classes.ul}>
                            <li className={classes.li}>Smart Agriculture</li>
                            <li className={classes.li}>Water Management</li>
                            <li className={classes.li}>Smart Cities</li>
                            <li className={classes.li}>Smart Parking</li>
                            <li className={classes.li}>Smart Retail</li>
                            <li className={classes.li}>Industry 4.0</li>
                            <li className={classes.li}>Smart Environment</li>
                            <li className={classes.li}>Smart Tourism</li>
                            <li className={classes.li}>Smart Tracking</li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                        <h3 className={classes.h3}>IoT solutions</h3>
                        <ul className={classes.ul}>
                            <li className={classes.li}>Smart Agriculture</li>
                            <li className={classes.li}>Water Management</li>
                            <li className={classes.li}>Smart Cities</li>
                            <li className={classes.li}>Smart Parking</li>
                            <li className={classes.li}>Smart Retail</li>
                            <li className={classes.li}>Industry 4.0</li>
                            <li className={classes.li}>Smart Environment</li>
                            <li className={classes.li}>Smart Tourism</li>
                            <li className={classes.li}>Smart Tracking</li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                        <h3 className={classes.h3}>IoT solutions</h3>
                        <ul className={classes.ul}>
                            <li className={classes.li}>Smart Agriculture</li>
                            <li className={classes.li}>Water Management</li>
                            <li className={classes.li}>Smart Cities</li>
                            <li className={classes.li}>Smart Parking</li>
                            <li className={classes.li}>Smart Retail</li>
                            <li className={classes.li}>Industry 4.0</li>
                            <li className={classes.li}>Smart Environment</li>
                            <li className={classes.li}>Smart Tourism</li>
                            <li className={classes.li}>Smart Tracking</li>
                        </ul>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                        <h3 className={classes.h3}>IoT solutions</h3>
                        <ul className={classes.ul}>
                            <li className={classes.li}>Smart Agriculture</li>
                            <li className={classes.li}>Water Management</li>
                            <li className={classes.li}>Smart Cities</li>
                            <li className={classes.li}>Smart Parking</li>
                            <li className={classes.li}>Smart Retail</li>
                            <li className={classes.li}>Industry 4.0</li>
                            <li className={classes.li}>Smart Environment</li>
                            <li className={classes.li}>Smart Tourism</li>
                            <li className={classes.li}>Smart Tracking</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={12}>
                    <Divider className={classes.divider} />{' '}
                </Grid>
                <Grid container className={classes.footer}>
                    <img src={logoWhite} alt="logo" className={classes.logo} />
                    <div className={classes.links}>© IoT SOTA | Terms and conditions | Privacy policy Cookies</div>
                    <div className={classes.follow}>
                        <div className={classes.followText}>Follow us: </div>
                        <div className={classes.followNetw}>
                            <img src={fb} className={classes.netw} alt="" />
                            <img src={fb} className={classes.netw} alt="" />
                            <img src={fb} className={classes.netw} alt="" />
                            <img src={fb} className={classes.netw} alt="" />
                        </div>
                    </div>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.linksMobile}>
                            <p>© IoT SOTA | Terms and conditions | Privacy policy Cookies</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default FooterSection;
