import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { Container } from '@mui/system';

import backgroundImage from '../../assets/bg/advice_bg.svg';

const AdviceSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            paddingTop: 120,
            background: theme.palette.white,
            height: 480,
            [theme.breakpoints.down('sm')]: {
                paddingTop: 80,
                height: 480
            }
        },
        background: {
            position: 'absolute',
            backgroundSize: 'cover',
            right: 0,
            marginTop: 190,
            height: 180,
            zIndex: 0,
            [theme.breakpoints.down('sm')]: {
                objectPosition: '380px 0'
            }
        },
        content: {
            height: 280,
            width: '100%',
            background: 'rgba(233, 233, 233, 0.40)',
            boxShadow: '0px 4px 15px 0px rgba(255, 255, 255, 0.70) inset',
            backdropFilter: 'blur(7.5px)',
            zIndex: 20,
            display: 'flex!important',

            [theme.breakpoints.down('lg')]: {
                display: 'block!important',
                height: 270
            },
            [theme.breakpoints.down('md')]: {
                display: 'block!important',
                height: 260
            },
            [theme.breakpoints.down('sm')]: {
                display: 'block!important',
                height: 320
            }
        },
        h1: {
            color: theme.palette.black,
            fontSize: 32,
            maxWidth: 620,
            fontWeight: 300,
            paddingTop: 50,
            paddingLeft: 20,
            fontFamily: 'Montserrat-Regular',
            [theme.breakpoints.down('lg')]: {
                fontSize: 26,
                paddingTop: 40,

                maxWidth: 720
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 26,
                paddingTop: 50
            }
        },
        btn: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '20px!important',
                fontSize: '16px!important',
                marginTop: '10px!important'
            }
        },
        btnComponent: {
            marginTop: 120,
            marginLeft: 120,
            [theme.breakpoints.down('lg')]: {
                marginTop: 30,
                marginLeft: 20
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                marginLeft: 0
            }
        }
    }));
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <img className={classes.background} src={backgroundImage} alt="" />
            <Container className={classes.content}>
                <h1 className={classes.h1}>Отримайте консультацію стосовно інтеграції IoT у вашому місті, чи на підприємстві</h1>
                <div className={classes.btnComponent}>
                    <Button className={classes.btn}>Отримати консультацію</Button>
                </div>
            </Container>
        </Box>
    );
};

export default AdviceSection;
