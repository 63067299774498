import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';

const IntegratorSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            position: 'relative',
            backgroundColor: theme.palette.white,
            marginBottom: 100
        },
        h1: {
            color: theme.palette.black,
            fontSize: 42,
            fontFamily: 'RF-Dewi-ex-Light',
            paddingTop: 70,
            paddingBottom: 30,
            maxWidth: 850,
            fontWeight: 300,
            [theme.breakpoints.down('lg')]: {
                fontSize: 32
            }
        },
        h3: {
            color: theme.palette.black,
            fontSize: 24,
            fontFamily: 'Montserrat-Regular',
            paddingBottom: 10,
            maxWidth: 850,
            fontWeight: 500,
            [theme.breakpoints.down('lg')]: {
                fontSize: 20
            }
        },
        sub: {
            fontSize: 16,
            fontFamily: 'Montserrat-Regular',
            color: theme.palette.black,
            lineHeight: '1.7'
        },
        box: {
            paddingLeft: 50,
            marginLeft: 70,
            paddingTop: 10,
            [theme.breakpoints.down('lg')]: {
                marginLeft: 0,
                marginTop: 50,
                paddingTop: 10,
                paddingLeft: 30,
                padding: 30
            }
        },
        ul: {
            color: theme.palette.sky,
            maxWidth: 380,
            fontFamily: 'RF-Dewi-ex-Regular',
            padding: 0,
            marginLeft: 20
        },
        li: {
            marginBottom: 25,
            lineHeight: '1.7'
        },
        list: {
            fontWeight: 600
        }
    }));
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Container>
                <h1 className={classes.h1}>Інтегратор Smart EcoLogic</h1>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <div className={classes.sub}>
                            «SmartEcoLogic» — єдиний інтегратор програмного забезпечення IoTSOTA. Команда SmartEcoLogic показала свою
                            ефективність на кількох спроектованих nа реалізованих проектах. Компанія здійснила активацію більше 10 000
                            приладів. Спеціалісти пройшли навчання та знають як налаштувати процес встановлення, налаштування, передачу та
                            прийом даних. SmartEcoLogic є авторизованим диллером обладнання для зняття і передачі даних.
                            <p>
                                SmartEcoLogic генерує рішення та зупускає в експлуатацію мережі лише своєю командою, яка спеціалізується на
                                програмну забезпеченні IoTSOTA та брала участь в розробці та тестуванні мережі.
                            </p>
                            <div className={classes.list}>
                                <br />- Моніторинг стану в онлайн-режимі
                                <br />- Використання хмарних сховищ
                                <br />- Вигрузка даних за вибрані періоди з сигментацією
                                <br />- Збереження даних на захищених серверах
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Box className={classes.box} sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderRight: { xs: 1, lg: 0 } }}>
                            <h3 className={classes.h3}>Переваги:</h3>
                            <ul className={classes.ul}>
                                <li className={classes.li}>Партнерство з провідними виробниками.</li>

                                <li className={classes.li}>Власний сервісний центр.</li>

                                <li className={classes.li}>Розроблена система навчання для постачальників послуг.</li>

                                <li className={classes.li}>Наявність всього необхідного обладнання.</li>

                                <li className={classes.li}>Супровід в процесі реалізації проекту та тестового періоду (3 міс.)</li>
                            </ul>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default IntegratorSection;
