import React from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

const ServiseItem = ({ props, index }) => {
    const useStyles = makeStyles((theme) => ({
        h2: {
            fontFamily: 'RF-Dewi-ex-Regular',
            fontSize: 24,
            color: 'rgba(0, 0, 0, 1)'
        },
        p: {
            color: 'rgba(152, 152, 152, 1)',
            fontFamily: 'Montserrat-Regular',
            fontSize: 18,
            maxWidth: 374,
            lineHeight: '1.7'
        },
        serviseItem: {
            height: 468,
            backgroundColor: theme.palette.white,
            marginBottom: 51,
            [theme.breakpoints.down('md')]: {
                height: '100%',
                marginBottom: 51
            }
        },
        image: {
            width: '100%',
            height: '100%',
            background: `url(${props.img}) no-repeat center center`,
            backgroundSize: 'cover',
            [theme.breakpoints.down('md')]: {
                height: 400
            }
        },
        textContainer: {
            marginTop: 86,
            marginLeft: 50,
            [theme.breakpoints.down('md')]: {
                marginTop: 42,
                marginLeft: 25,
                marginRight: 20
            }
        },
        btn: {
            marginTop: '35px!important',
            [theme.breakpoints.down('md')]: {
                marginTop: '5px!important',
                marginBottom: '20px!important'
            }
        }
    }));

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const even = isMobile ? false : !(index % 2);
    return (
        <Grid container className={classes.serviseItem}>
            <Grid container sx={{ boxShadow: 5 }}>
                {even ? (
                    <>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className={classes.textContainer}>
                                <h2 className={classes.h2}>{props.title}</h2>
                                <p className={classes.p}>{props.text}</p>
                                <Button className={classes.btn}>Детальніше</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.image} />
                    </>
                ) : (
                    <>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.image} />
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className={classes.textContainer}>
                                <h2 className={classes.h2}>{props.title}</h2>
                                <p className={classes.p}>{props.text}</p>
                                <Button className={classes.btn}>Детальніше</Button>
                            </div>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};
export default ServiseItem;
