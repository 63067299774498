import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useGlobalState } from '.';
import { SwiperSlide } from 'swiper/react';

const DeviceItem = ({ props, index }) => {
    const useStyles = makeStyles((theme) => ({
        h2: {
            fontSize: 16,
            fontFamily: 'RF-Dewi-ex-Light'
        },
        img: {
            height: 100,
            width: 100
        },
        item: {
            textAlign: 'center',
            width: '100%'
        },
        area: {
            cursor: 'pointer'
        }
    }));

    const classes = useStyles();
    const [sitemIndex, setIndex] = useGlobalState('itemIndex');

    return (
        <Grid item xs={12} sm={12} md={4} lg={2} className={classes.item}>
            <Box
                className={classes.area}
                onClick={() => {
                    setIndex(index);
                }}
            >
                <img src={props?.img} alt="" />
                <h2 className={classes.h2}>{props?.header}</h2>
            </Box>
        </Grid>
    );
};
export default DeviceItem;
