import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Grid, Container } from '@mui/material';
import { createGlobalState } from 'react-hooks-global-state';
import DeviceItem from './item';
import Ellipse9 from '../../assets/devices/Ellipse9.jpg';
import Ellipse10 from '../../assets/devices/Ellipse10.jpg';
import Ellipse11 from '../../assets/devices/Ellipse11.jpg';
import Ellipse12 from '../../assets/devices/Ellipse12.jpg';
import Ellipse13 from '../../assets/devices/Ellipse13.jpg';
import Ellipse14 from '../../assets/devices/Ellipse14.jpg';

const items = [
    {
        img: Ellipse9,
        header: 'Далекобійність',
        text: 'Пристрої які ми використовуємо забезпечують дальній зв’язок (до 15 км) між датчиками та базовими станціями, що дає можливість створити мережу в 2-3 рази меншою за кількістю базових станцій порівняно з стільниковими.'
    },
    {
        img: Ellipse10,
        header: 'Акумулятор',
        text: 'Для передачі та прийому даних пристроям потрібен низький струм (менше 50 мА), що суттєво зменшує споживання енергії пристроями та забезпечує час автономної роботи до 10 років.'
    },
    {
        img: Ellipse11,
        header: 'Двонаправлений',
        text: 'Двонаправлена ​​комунікація забезпечує широкий спектр використання мережі в майбутньому.'
    },
    {
        img: Ellipse12,
        header: 'Проникнення в приміщення',
        text: 'Радіомодуляція пристроїв дозволяє глибоко проникнути всередину приміщення чи точок де стоять пристрої обліку, навіть якщо вони розташовані глибоко під землею.'
    },
    {
        img: Ellipse13,
        header: 'Неліцензійний діапазон',
        text: 'Мережі, до яких підключаються пристрої обліку, розгортаються в безкоштовних діапазонах ISM (ЄС 868, AS 923, США 915 МГц), що дозволяє будь-якому постачальнику послуг розгортати та експлуатувати мережі без необхідності отримувати ліцензію на будь-яку частоту.'
    },
    {
        img: Ellipse14,
        header: 'Вартість та надійність',
        text: 'Вартість пристроїв та базових станцій і потреба в невеликій кількості, робить вартість всієї мережі достатньо прийнятною.Та дає можливість розгортати мережу всього за кілька місяців та з мінімальними вкладеннями. Пристрої відомих перевірених виробників: модеми, накладки, станції.'
    }
];
export const { useGlobalState } = createGlobalState({ itemIndex: 0 });

const DevicesSection = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            maxWidth: '100vw',
            position: 'relative',
            backgroundColor: theme.palette.white,
            marginBottom: 170
        },
        box: {
            marginTop: 80,
            border: '1px solid black',
            width: '100%',
            height: 210,
            paddingRight: 120,
            paddingLeft: 120,
            paddingTop: 20,

            [theme.breakpoints.down('md')]: {
                paddingRight: 30,
                paddingLeft: 30,
                paddingTop: 20,
                paddingBottom: 20,
                height: '100%'
            }
        },
        h1: {
            fontWeight: 300,
            fontSize: 42,
            fontFamily: 'RF-Dewi-ex-Regular',
            marginBottom: 70,
            [theme.breakpoints.down('sm')]: {
                fontSize: 32
            }
        },
        h2: {
            fontSize: 24,
            fontFamily: 'RF-Dewi-ex-Regular'
        },
        content: {
            marginTop: 20,
            fontFamily: 'Montserrat-Regular',
            color: theme.palette.secondaryLightGray,
            maxWidth: 900
        },
        dot: {
            paddingRight: 10
        }
    }));
    const classes = useStyles();
    const [itemIndex] = useGlobalState('itemIndex');
    const single = items[itemIndex];
    return (
        <Box className={classes.container}>
            <Container>
                <h1 className={classes.h1}>Пристрої IoT SOTA</h1>

                <Grid container>
                    {items.map((item, index) => (
                        <DeviceItem props={item} key={index} index={index} />
                    ))}

                    <Box className={classes.box}>
                        <div className={classes.text}>
                            <h2 className={classes.h2}>
                                {' '}
                                <span className={classes.dot}>&bull;</span>
                                {single?.header}
                            </h2>
                            <p className={classes.content}>{single?.text}</p>
                        </div>
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
};

export default DevicesSection;
